import { io } from "socket.io-client"
import moment from "moment-timezone"
import i18n from "./i18n";

import AnnouncementActions from "./actions/AnnouncementActions"
import StatusActions from "./actions/StatusActions"
import HumanChatStatusActions from "./actions/HumanChatStatusActions";
import ChatSettingActions from './actions/ChatSettingActions'
import ConnectionActions from "./actions/ConnectionActions"
import SessionActions from "./actions/SessionActions"
import CaseActions from "./actions/CaseActions"
import DropAreaActions from "./actions/DropAreaActions"
import DisconnectActions from "./actions/DisconnectActions"
import FormErrMsgActions from "./actions/FormErrMsgActions"
import LAAvailabilityActions from "./actions/LAAvailabilityActions"
import MessageActions from './actions/MessageActions'
import ChatMessageActions from './actions/ChatMessageActions'
import MenuActions from './actions/MenuActions'
import SubmitActions from "./actions/SubmitActions";
import SuggestionActions from './actions/SuggestionActions'
import TypingActions from "./actions/TypingActions";
import IconActions from "./actions/IconActions"
import StatusLampActions from './actions/StatusLampActions'
import NameActions from './actions/NameActions'
import InputActions from "./actions/InputActions";
import PersonelActions from "./actions/PersonelActions";
import NotificationActions from "./actions/NotificationActions";
import BotErrorActions from "./actions/BotErrorActions";
import QuestionnaireActions from "./actions/QuestionnaireActions";
import UploadActions from "./actions/UploadActions";
import ModalActions from "./actions/ModalWindowActions";
import FormFormatActions from "./actions/FormFormatActions";
import ShopActions from "./actions/ShopActions";
import TitleActions from "./actions/TitleActions";
import BotMaintenanceActions from "./actions/BotMaintenanceActions";
import QueueActions from "./actions/QueueActions"
import { settings } from "./config/settings";
import { postMessage as postMessageToOperator,
         onMessageChange, onStatusChange,
        readMessage, signInAnonymously,
        requestChat, listenSession,
        listenCase, closeChat,
        abandon} from 'chat-common/firebase'
import UserInfoActions from "./actions/UserInfoActions"
import StaffActions from "./actions/StaffActions";


const path = (!process.env.REACT_APP_IS_LOCAL) ? 
  (service => {
    switch(service){
      case 'direct':
        return '/direct/socket.io/';
      case 'fashion':
        return '/fashion/socket.io/';
      default:
        return '';
    }
  })(process.env.REACT_APP_SERVICE) : '';
const socket = io('', { transports: ["websocket"], path })

socket.on('request-queue', (queueState) => {
  QueueActions.set(queueState)
})
socket.on('left-navi:operator-based-from-queue', (isDisplay) => {
  MenuActions.updateOperator(isDisplay);
})
socket.on('change-liveagent-status', (availability) => {
  LAAvailabilityActions.updated(availability)
})
socket.on("rlogin:failed", ()=> {
  window.location='https://mainmenu.rms.rakuten.co.jp/?act=app_login_error'
})
socket.on("cookie:set",(tmpid) => {
  const expires = `${moment.tz('GMT').add(3,'M').format('ddd, d-MMM-YYYY hh:mm:ss')} GMT`
  document.cookie=`tmpid=${tmpid}; expires=${expires}; secure`
})
socket.on('login:suceeded', ()=>{
  //......//
})
socket.on('questionnaire:set', (item)=>{
  QuestionnaireActions.set(item)
})
socket.on('user-info:set-default-value', ({item, value})=>{
  FormErrMsgActions.clear();
  UserInfoActions.set(item, value)
})
socket.on("connect", () => {
  InputActions.enable()
  SubmitActions.enable()
  StatusLampActions.isOnline();
  ConnectionActions.connect();
  StatusActions.connect()
})
socket.on("connect error", () => {
  InputActions.disable()
  SubmitActions.disable()
  StatusLampActions.isOffline();
  ConnectionActions.error();
  StatusActions.error()
})
socket.on("disconnect", (reason) => {
  DropAreaActions.hide()
  DropAreaActions.disable()
  DisconnectActions.disable()
  InputActions.disable()
  SubmitActions.disable()
  UploadActions.disable()
  UploadActions.hide();
  StatusLampActions.isOffline();
  ConnectionActions.disconnect()
  StatusActions.disconnect()
})
socket.io.on("reconnect", (data) => {
  InputActions.disable()
  SubmitActions.disable()
  DropAreaActions.hide()
  DropAreaActions.disable()
  StatusLampActions.isOffline();
  ConnectionActions.reconnect(data);
  StatusActions.reconnect(data)
})
// reconnect_attemptとrecconectingはreconnect_attemptに統合
socket.io.on("reconnect_attempt",  (data) => {
  InputActions.disable();
  SubmitActions.disable();
  DropAreaActions.hide()
  DropAreaActions.disable()
  StatusLampActions.isOffline();
  ConnectionActions.reconnecting(data);
  StatusActions.reconnecting(data)
})
socket.io.on("reconnect_error", () => {
  InputActions.disable();
  SubmitActions.disable();
  DropAreaActions.hide()
  DropAreaActions.disable()
  StatusLampActions.isOffline();
  ConnectionActions.reconnectError();
  StatusActions.reconnectError()
})
socket.io.on("reconnect_failed", () => {
  InputActions.disable();
  SubmitActions.disable();
  DropAreaActions.hide()
  DropAreaActions.disable()
  StatusLampActions.isOffline();
  ConnectionActions.reconnectFailed();
  StatusActions.reconnectFailed()
})
socket.on('bot:maintenance', ()=>{
  BotErrorActions.show();
  BotMaintenanceActions.change(true);
})
socket.on('operator:maintenance', ({activities})=>{
  SubmitActions.changeOpponent('bot');
  MenuActions.enable();
  MessageActions.addBotMessage(activities[0]);
  InputActions.initialize()
  InputActions.enable();
  InputActions.focus();
})
socket.on('chat:channel-control', (displayChannels) => {
  MenuActions.initialize(displayChannels,true)
})
socket.on("chat:set-chat-session-id", (chatSessionId) => {ChatSettingActions.setSessionId(chatSessionId)})
socket.on("chat:announcement", (announcementEl) => {AnnouncementActions.show('announcementTitle',announcementEl)})
socket.on('chat:get-shop:success', (shopMaster={}) => {
  ShopActions.succeed(shopMaster);
  const { shopName='', url='' } = shopMaster;
  const targetLogo = `https://thumbnail.image.rakuten.co.jp/@0_mall/${url}/logo/logo1.jpg`;
  IconActions.set(targetLogo);
  NameActions.set(`${shopName}${i18n.t('profileName.shop')}`);
  TitleActions.change(`${i18n.t('title')}${shopName}`);
  MessageActions.setBotIcon(targetLogo);
})
socket.on('chat:get-shop:failure', () => {
  ShopActions.failed();
  IconActions.set('', '#ddd');
  NameActions.set('profileName.shopInfoFailed');
  MessageActions.setBotIcon('');
})

socket.on('reception:session-timeout', ()=>{
  setTimeout(window.location.reload(),1000)
});
socket.on('operator:session-timeout', () => {
  DropAreaActions.hide()
  DropAreaActions.disable()
  HumanChatStatusActions.initialize()
  setTimeout(()=>{  HumanChatStatusActions.isTimeout()}, 3000)
  StatusLampActions.isOffline()
  PersonelActions.set('')
  NameActions.set('statusMessage.disconnect')
  InputActions.clear()
  InputActions.disable()
  InputActions.changePlaceholder('placeholder.disconnect')
  SubmitActions.disable()
  UploadActions.disable()
  UploadActions.hide();
  ConnectionActions.disconnect()
})
socket.on("bot:get-message:success", ({activities}) => {
  MessageActions.addBotMessage(activities[0])
})
socket.on("bot:input-disable",(flag)=>{
  if(flag){
    InputActions.disable()
    InputActions.changePlaceholder('placeholder.inputDisable')
  } else {
    InputActions.initialize()
  }
})
socket.on("reception:select-category", ({activities}) => {
  SubmitActions.changeOpponent('reception-category')
  MessageActions.addBotMessage(activities[0]) //カテゴリの表示
  MenuActions.disable()
  InputActions.changePlaceholder('placeholder.selectCategory')
  InputActions.disable();
})
socket.on("reception:input-email", ({activities}) => {
  SubmitActions.changeOpponent('reception-input-email')
  MenuActions.disable()
  MessageActions.addBotMessage(activities[0])
  InputActions.changePlaceholder('placeholder.inputEmail')
  InputActions.enable();
  InputActions.focus();
})
socket.on("reception:input-detail", ({ formKey, categoryKey, purchaseHistory }) => {
  SubmitActions.changeOpponent('reception-input-detail')
  SubmitActions.disable(true);
  InputActions.changePlaceholder('')
  InputActions.disable();
  FormErrMsgActions.clear();
  FormFormatActions.set(formKey, categoryKey, purchaseHistory);
  ModalActions.show();
})
socket.on("reception:send-inquiryForm", ({activities}) => {
  MessageActions.addBotMessage(activities[0])
})
socket.on("reception:quit", ({activities}) => { 
  const firstActivities = (activities && activities.length > 0) ? activities[0] : undefined
  if (firstActivities) MessageActions.addBotMessage(activities[0])
  SubmitActions.changeOpponent('bot')
  MenuActions.enable()
  InputActions.initialize()
  InputActions.enable();
  InputActions.focus();
})

socket.on("operator:wait-information-changed", ()=>{
  TypingActions.setName('profileName.connectingOperator')
  HumanChatStatusActions.isRequesting()
  SubmitActions.changeOpponent('operator')
  InputActions.changePlaceholder('placeholder.connectingOperator')
  InputActions.disable()
  IconActions.set('img/operator_icon_pc.png')
  DisconnectActions.show()
  DisconnectActions.enable()
  setTimeout(()=>StatusLampActions.isOffline())
  PersonelActions.set('')
  NameActions.set('profileName.connectingOperator')
  MenuActions.hide()
})
socket.on('operator:reconnect', (isInputEnabled)=>{
  if (isInputEnabled) {
    StatusLampActions.isOnline()
    InputActions.enable()
    SubmitActions.enable()
    DisconnectActions.enable()
    if(settings.socketAction.upload){
      UploadActions.show();
      UploadActions.enable();
      DropAreaActions.enable();
      DropAreaActions.hide();
    }
  } else {
    InputActions.disable()
    SubmitActions.disable()
    DisconnectActions.enable()
  }
})
socket.on("operator:agent-not-typing", () => {
  //ここないなー
  TypingActions.agentNotTyping()
})
socket.on("operator:agent-typing", () => {
  //ここないなー
  TypingActions.agentTyping()
})
socket.on("operator:participant-added", (message)=>{
  const { name } = message
  if(settings.socketAction.upload){
    UploadActions.show();
    UploadActions.enable();
    DropAreaActions.enable();
    DropAreaActions.hide();
  }
  TypingActions.setName(name)
  HumanChatStatusActions.isConnected()
  SubmitActions.changeOpponent('operator')
  IconActions.set('img/operator_icon_pc.png')
  StatusLampActions.isOnline()
  if(name instanceof Array){
    NameActions.set(name)
  } else {
    PersonelActions.set('profileName.operator')
    NameActions.set([name])
  }
  InputActions.changePlaceholder('placeholder.operator')
  InputActions.enable()
  MenuActions.hide()
  DisconnectActions.show()
})
socket.on("operator:message-posted", (message)=>{
  const { text } = message
  MessageActions.addOperatorMessage(text)
  NotificationActions.show(text)
  socket.emit('operator:message-posted:success', text)
})
socket.on('bot:set-suggestion', (defaultSuggestions) => { SuggestionActions.setDefaultItem(defaultSuggestions) })
socket.on('bot:get-suggestion:success', (suggestions) => { SuggestionActions.show(suggestions) })
const commonFuncOnDisconnect = () => {
  SubmitActions.disable()
  DisconnectActions.disable()
  DisconnectActions.hide()
  InputActions.clear()
  InputActions.changePlaceholder('placeholder.disconnect')
  InputActions.disable()
  StatusLampActions.isOffline()
  PersonelActions.set('')
  NameActions.set('profileName.disconnect')
  UploadActions.hide();
  UploadActions.disable();
}
socket.on("operator:user-disconnect", () => {
  HumanChatStatusActions.initialize()//css更新の為必要
  HumanChatStatusActions.isDisconnectedByUser()
  commonFuncOnDisconnect()
})
socket.on("operator:agent-disconnect", () => {
  HumanChatStatusActions.initialize()//css更新の為必要
  HumanChatStatusActions.isDisconnectedByAgent()
  commonFuncOnDisconnect()
})
socket.on("operator:upload-file:processing",(fileinfo,isImage,lastFrom) => {
  ChatMessageActions.sendingFile(fileinfo,isImage,lastFrom)
})
socket.on("operator:upload-file:success",(id,fileinfo,caseId, sessionId,lastFrom)=>{
  postMessageToOperator(sessionId,'user',{
    text: `ファイル送信：${fileinfo.filename}`,
    contentName: fileinfo.filename,
    contentType: fileinfo.filetype,
    contentUrl: fileinfo.contentUrl,
  },(lastFrom === 'staff'))
  MessageActions.completedUpload(id)
})
socket.on('operator:upload-file:failure', (id, text, caseId, sessionId, lastFrom) => {
  postMessageToOperator(sessionId,'user',{
    text,
  },(lastFrom === 'staff'))
  MessageActions.completedUpload(id);
})
/*
socket.on('window-open', ()=>{
  console.log('window-open')
  window.opener.postMessage('test','https://chat.ichiba.faq.rakuten.co.jp/')
})
*/

export default {
  startBot(opponent, conversationCase, clientId, shopId, orderNumber, chatSessionId, healthChecker, pageUrl, urlPath, referrer, id, lang, facilityNumber, isInputEnabled) {
    socket.emit(`${opponent}:start`,  { conversationCase, clientId, shopId, orderNumber, chatSessionId, healthChecker, pageUrl, urlPath, referrer, id, lang, facilityNumber, isInputEnabled, } )
  },
  postMessage(opponent, text, inputType, contents=[], questionnaire={}, userInfo, isProduction, session, lastFrom) {
    //オペレーターが相手の場合、sessionに書き込む
    if(opponent === "operator"){
      postMessageToOperator(session,'user',{text},(lastFrom === 'staff'))
    } else {
      socket.emit(`${opponent}:post-message`, {
        text,
        inputType,
        screenSize: `${window.screen.availWidth}x${window.screen.availHeight}`,
        messageHistory: contents,
        questionnaire,
        userInfo,
        isProduction,
      })
    }
  },
  postDetail({opponent, info, skill, contents=[], questionnaire={}, isCancel=true}) {
    //Todo
    //ここがなくなり
    // Firebase認証
    // createCase、createSessionを行い
    //sessionをヒアリング状態にする
    //caseをヒアリング状態にする
    const postContents = contents.map((content)=>{
      const { text: contentText, attachments=[], now, from } = content
      const attachementsTexts = attachments.map(attachment => attachment.content.originalText )
      const text = [contentText, ...attachementsTexts].join('\n')
      const timestamp = moment.tz(now, 'Asia/Tokyo').toDate()
      return {from, text, timestamp }
    })
    const inquiryContent = info.find((i) => i.id === 'inquiryContent') || {}
    const fixedInfo = info.map(({ purchaseHistory, ...rest }) => rest)
    signInAnonymously(
      ()=>{
        console.log("Success")
      },
      (e) => {
        console.log(e)
      },
      (user) => {
        if(user) {
          // salesforceの都合
          const requestSkill = skill === 'PAY' ? 'SHP' : skill
          requestChat(
            {text: inquiryContent.value || ''},
            fixedInfo,
            requestSkill,
            postContents,
            user.uid
          ).then(value=>{
            const { caseId, sessionId } = value
            socket.emit('operator:request-store-easyid', { caseId })
            socket.emit('operator:chat-open')
            this.connectMonitor(caseId,sessionId)
            listenSession(sessionId, (error) => {
              // sessionのsubscribeがエラーで止まった時に呼ばれるコールバック
              socket.emit('notify-to-server:stop-listening-session', { sessionId, error })
            })
            listenCase(caseId, (error) => {
              // casesのsubscribeがエラーで止まった時に呼ばれるコールバック
              socket.emit('notify-to-server:stop-listening-case', { caseId, error })
            })
            SessionActions.set(sessionId)
            CaseActions.set(caseId)
            //
            TypingActions.setName('profileName.connectingOperator')
            HumanChatStatusActions.isRequesting()
            SubmitActions.changeOpponent('operator')
            InputActions.changePlaceholder('placeholder.connectingOperator')
            InputActions.disable()
            IconActions.set('img/operator_icon_pc.png')
            DisconnectActions.show()
            DisconnectActions.enable()
            setTimeout(()=>StatusLampActions.isOffline())
            PersonelActions.set('')
            NameActions.set('profileName.connectingOperator')
            MenuActions.hide()
          }).catch(e => {
            console.log(e)
          })
          //setUseruid
          //
        } 
      }
    )
    //socket.emit(`${opponent}:post-detail`, { info, screenSize: `${window.screen.availWidth}x${window.screen.availHeight}`, messageHistory: contents, questionnaire, isCancel })
  },
  cancelReception(){
    socket.emit("reception-input-detail:cancel")
  },
  getSuggestion(text) {
    socket.emit("bot:get-suggestion", { text })
  },
  report({timing="",place="",text="",action=""}) {
    socket.emit("bot:user-action-report", {timing,place,content: text,action})
  },
  sendUserTyping(){
    //ここないなー
    socket.emit("operator:user-typing")
  },
  sendUserNotTyping(){
    //ここないなー
    socket.emit("operator:user-not-typing")
  },
  sendUserTextAsSneakPeak(caseId,staffId,text){
    //ここないなー
    socket.emit("operator:sneak-peak", {caseId,staffId,text})
  },
  connectMonitor(caseId,sessionId){
    socket.emit("operator:monitor-action", {caseId,sessionId})
  },
  uploadFile(filename, filetype, filesize, data_uri, id, sessionId, caseId, lastFrom) {
    socket.emit("operator:upload-file", { filename, filetype, filesize, data_uri, id, sessionId, caseId, lastFrom})
  },
  uploadFileSizeOver(id) {
    socket.emit("operator:upload-file-size-over",id)
  },
  abandonChat(caseId, sessionId){
    abandon(caseId, sessionId)
  },
  disconnectChat(caseId,sessionId){
    closeChat(caseId,sessionId,'Visitor')
  },
  checkChatDisconnect() {
    //socket.emit("operator:disconnect")
  },
  linkOpened({targetLink}){
    socket.emit("bot:user-link-opened", {targetLink})
  },
  close() {
    socket.close()
  },
}



/*
 ============================================
        Firestore
 ============================================
*/

onStatusChange((caseId, data)=>{
  const { status, displayName, staffId } = data || {}
  switch(status){
    case 'transfer':
      StaffActions.clear()
      TypingActions.setName('profileName.transferOperator')
      HumanChatStatusActions.isTransfering()
      SubmitActions.changeOpponent('operator')
      InputActions.changePlaceholder('placeholder.transferOperator')
      InputActions.disable()
      IconActions.set('img/operator_icon_pc.png')
      DisconnectActions.show()
      DisconnectActions.enable()
      setTimeout(()=>StatusLampActions.isOffline())
      PersonelActions.set('')
      NameActions.set('profileName.transferOperator')
      MenuActions.hide()
      return 
    case 'inProgress':
      StaffActions.set(staffId)
      StatusLampActions.isOnline()
      InputActions.enable()
      SubmitActions.enable()
      DisconnectActions.enable()
      DisconnectActions.useDisconnect()
      HumanChatStatusActions.isConnected()
      IconActions.set('img/operator_icon_pc.png')
      SubmitActions.changeOpponent('operator')
      if(settings.socketAction.upload){
         UploadActions.show();
         UploadActions.enable();
         DropAreaActions.enable();
         DropAreaActions.hide();
      }
      TypingActions.setName(displayName)
      if(displayName instanceof Array){
        NameActions.set(displayName)
      } else {
        PersonelActions.set('profileName.operator')
        NameActions.set([displayName])
      }
      InputActions.changePlaceholder('placeholder.operator')
      InputActions.enable()
      MenuActions.hide()
      DisconnectActions.show()
      return
    case 'abandoned':
      StatusActions.initialize()
      setTimeout(()=>{HumanChatStatusActions.isDisconnectedByUser()},3000)
      StaffActions.clear()
      SubmitActions.disable()
      DisconnectActions.disable()
      DisconnectActions.hide()
      InputActions.clear()
      InputActions.changePlaceholder('placeholder.disconnect')
      InputActions.disable()
      StatusLampActions.isOffline()
      PersonelActions.set('')
      NameActions.set('profileName.disconnect')
      UploadActions.hide();
      UploadActions.disable();
      return
    case 'closed':
      StaffActions.clear()
      SubmitActions.disable()
      DisconnectActions.disable()
      DisconnectActions.hide()
      InputActions.clear()
      InputActions.changePlaceholder('placeholder.disconnect')
      InputActions.disable()
      StatusLampActions.isOffline()
      PersonelActions.set('')
      NameActions.set('profileName.disconnect')
      UploadActions.hide();
      UploadActions.disable();
      return
    default:
      return
  }
})
onMessageChange((sessionId, data) => {
  //Messagesに更新があれは、ここが実行される
  // オペレーターがメッセージ送った時
  //  ユーザーが受け取ってない時
  //  ユーザーが受け取った時
  // ユーザーがメッセージ送った時
  //  オペレーターが受け取ってない時
  //  オペレーターが受け取った時
  const { userReadline, messages={}, disconnectedBy } = data || {}
  if(disconnectedBy){
    const message =  createQuestionnaireMessage()
    MessageActions.addBotMessage(message)
    HumanChatStatusActions.initialize()
    StatusActions.initialize() //自動切断の場合、socketのステータス表示を見せないようにする
    const disconnectAction = {
      Visitor: HumanChatStatusActions.isDisconnectedByUser,
      Agent:   HumanChatStatusActions.isDisconnectedByAgent,
    }[disconnectedBy]
    disconnectAction();
    socket.disconnect();
    ConnectionActions.disconnect()
  }
  const messageArr = Object.values(messages).filter(message => message.createTime).sort((messageA,messageB) => {
    return (messageA.createTime.seconds - messageB.createTime.seconds)
  })
  if(userReadline === messageArr.length) return
  readMessage(sessionId, 'user', messageArr.length)
  ChatMessageActions.updateMessages(messageArr)
})

const createQuestionnaireMessage = () => {
  return {
    attachments: [
      {
        content: {
          text: `<p>${i18n.t('questionnaireMessage')}</p>`,
        },
      }
    ]
  }
}